
.canvas {
    display: flex;
    max-width: 100vw;
    max-height: 80vh;
    background-color: white;
    overflow: hidden;
}

.Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    background-color: white;
    padding: 10px;
}

.startIcon {
    position: absolute;
    right: 10px;
    width: 20px;
    height: 20px;
    padding:10px;
    color: black;
    margin-right: 20px;
}

.startIcon:active {
    cursor: pointer;
    color: darkgrey;
}

.startIcon:hover {
    cursor: pointer;
}

.pauseIcon {
    position: absolute;
    right: 60px;
    width: 20px;
    height: 20px;
    padding:10px;
    color: black;
    margin-right: 20px;
}

.pauseIcon:active {
    cursor: pointer;
    color: darkgrey;
}

.pauseIcon:hover {
    cursor: pointer;
}

.pauseIconActive {
    position: absolute;
    right: 60px;
    width: 20px;
    height: 20px;
    padding:10px;
    color: white;
    margin-right: 20px;
    color: black;
}

.pauseIconActive:active {
    cursor: pointer;
    color: darkgrey;
}

.pauseIconActive:hover {
    cursor: pointer;
}

.AppName {
    position: absolute;
    font-size: 20px;
    font-weight: bold;
    margin-left: 0px;
    left: 70px;
}

.GameHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    height: 50px;
    background-color: white;
}


.GameHeader > div {
    justify-content: center;
    align-items: center;

}

.GameHeader > div > p {
    padding-left: 50px;
    padding-right: 50px;
}

.GameHeader > div > h1 {
    margin:0;
}

.GameArea {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-height: 80vh;
    background-color: white;
    color: black;
    overflow: hidden;
}

.Time {
    position: absolute;
    left:40%;
}


.Footer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: white;
    bottom: 0;
}

.Menu {
    z-index: 1;
    position: absolute;
    top:70px;
    left: 0;
    right:0;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    padding-right: 50px;
    border: solid 1px black;
}

.menuIcon {
    width: 20px;
    height: 20px;
    margin: 10px;
}

.menuIcon:active {
    cursor: pointer;
    color: darkgrey;
}

.menuIcon:hover {
    cursor: pointer;
}

.App {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.PauseScreen {
    position: absolute;
    top:70px;
    bottom:0;
    left: 0;
    right:0;
    overflow: hidden;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    color: white;
    text-align: center;
    padding-top:20px;

}




